import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Post from '../Post';
import { breakpoints } from '../../constants';

const PostList = styled.div`
  @media screen and (min-width: ${breakpoints.bp1}) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 1em;
    gap: 1em;
  }

  @media screen and (min-width: ${breakpoints.bp4}) {
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  }
`;

export const fragment = graphql`
  fragment ALL_POSTS_FRAGMENT on MarkdownRemarkConnection {
    totalCount
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          datetime
          title
          excerpt
          category
          tags
        }
      }
    }
  }
`;

const Posts = ({ posts = [] }) => {
  if (!posts.length) return null;

  return (
    <PostList>
      {posts.map(post => (
        <Post key={post.id} {...post} />
      ))}
    </PostList>
  );
};

export default Posts;
