import React from "react";
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import Posts from '../components/Posts';
import { selectPosts } from '../selectors/selectPosts';

export const query = graphql`
  query CATEGORY_TEMPLATE_QUERY(
    $category: String,
    $skip: Int!,
    $limit: Int!
  ) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___datetime], order: DESC }
      filter: {
        fileAbsolutePath: { regex:  "\/content/posts/" }
        frontmatter: { category: { in: [$category] } }
      }
      limit: $limit
      skip: $skip
    ) {
      ...ALL_POSTS_FRAGMENT
    }
  }
`;

const CategoryTemplate = ({
  data,
  location: {
    href
  },
  pageContext: {
    category
  }
}) => {
  const { posts } = selectPosts(data);
  const title = `${category}s`;
  const description = `All posts on Assortment categorised with ${category}`;

  return (
    <PageLayout>
      <MetaTags
        url={href}
        metaTitle={title}
        metaDescription={description}
      />
      <Banner
        title={title}
        excerpt={description}
        link={{
          href: '/',
          text: 'View all posts'
        }}
      />
      <Posts posts={posts} />
    </PageLayout>
  );
};

export default CategoryTemplate;
