import React from 'react';
import { ThemeProvider } from '@emotion/react';
import {
  Wrapper,
  MetaData,
  Category,
  PostLink,
  Excerpt,
  Tags,
  Tag
} from './styled';
import { brandColors } from '../../constants';
import { deriveDatetimeFromString, deriveSlugFromString } from '../../utils';

const Post = ({
  id,
  path,
  datetime,
  title,
  excerpt,
  category,
  tags
}) => (
  <ThemeProvider theme={{ ...brandColors[deriveSlugFromString(category.title)] }}>
    <Wrapper>
      <MetaData>
        <Category to={category.path}>{category.title}</Category>
        <time dateTime={datetime}>{deriveDatetimeFromString(datetime)}</time>
      </MetaData>
      <h1><PostLink to={path}>{title}</PostLink></h1>
      <Excerpt>{excerpt}</Excerpt>
      <Tags>
        {tags.map((tag, index) => <Tag to={tag.path} key={tag.title}>{tag.title}</Tag>)}
      </Tags>
    </Wrapper>
  </ThemeProvider>
);

export default Post;
