import { deriveSlugFromString } from '../utils';

export const selectTagsFromPost = tags => {
  if (tags === null || !tags.length) return [];

  return tags.map(tag => ({
    title: tag,
    path: `/tags/${deriveSlugFromString(tag)}`
  }));
};

export const selectCategoryFromPost = category => {
  if (!category) return [];

  return {
    title: category,
    path: `/categories/${deriveSlugFromString(category)}`
  };
};

export const selectPosts = ({ allMarkdownRemark: { totalCount = 0, edges = [] } = [] }) => {
  const posts = edges.map(
    ({
      node: {
        id,
        fields: { slug },
        frontmatter: { datetime, title, excerpt, category, tags }
      }
    }) => {
      return {
        id,
        datetime,
        title,
        path: `/posts${slug}`,
        excerpt,
        tags: selectTagsFromPost(tags),
        category: selectCategoryFromPost(category)
      };
    }
  );

  return {
    posts,
    postCount: totalCount
  };
};

export const selectPostPagination = ({ prev, next }) => ({
  prev: prev.node
    ? {
        title: prev.node.frontmatter.title,
        path: prev.node.frontmatter.path
      }
    : {},
  next: next.node
    ? {
        title: next.node.frontmatter.title,
        path: next.node.frontmatter.path
      }
    : {}
});
